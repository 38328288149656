@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 40 100% 94%;
    --foreground: 39 45% 27%;

    --card: 43 100% 84%;
    --card-foreground: 39 45% 27%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 39 45% 27%;
 
    --primary: 39 45% 37%;
    --primary-foreground: 40 100% 94%;
 
    --secondary: 44 64% 69%;
    --secondary-foreground: 39 45% 27%;
 
    --muted: 0 0% 100%;
    --muted-foreground: 39 10% 65%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 39 45% 37%;
    --input: 39 45% 27%;
    --input-bg: 0 0% 100%;
    --ring: 39 45% 37%;
 
    --radius: 0.9rem;

    --heroAccent: 41 100% 68%;
    --heroAccent-foreground: 40 100% 4%;

    --shopAccent: 0 0% 18%;
    --shopAccent-foreground: 40 100% 94%;


    --loginAccent: 40 31% 52%;
    --loginAccent-foreground: 40 100% 95%;


    --defaultAccent: 40 31% 52%;
    --defaultAccent-foreground: 40 100% 95%;
  }
 
  .dark {
    --background: 40, 20%, 10%;
    --foreground: 43 100% 84%;
 
    --card: 43, 22%, 16%;
    --card-foreground: 210 40% 98%;
 
    --popover:  40, 12%, 15%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 39 45% 37%;
    --primary-foreground: 40 100% 94%;
 
    --secondary: 39 45% 25%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 95% 80%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 39, 45%, 30%;
    --input: 39 45% 27%;
    --ring: 39, 45%, 37%;


    --shopAccent: 40 20% 6%;
    --shopAccent-foreground: 40 100% 94%;


    --loginAccent: 40 31% 22%;
    --loginAccent-foreground: 40 100% 90%;


    --defaultAccent: 40 31% 22%;
    --defaultAccent-foreground: 40 100% 90%;
}
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
